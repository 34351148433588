import React from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";

import Layout from "../components/layout/layout";
import Container from "../components/container";
import FooterCta from '../components/FooterCta';
import Separator from '../components/Separator';

import HubspotForm from "../components/HubspotForm";

interface ILandingPageProps {
  data: {
    topImage: {
      publicURL: string;
    },
    midImage: {
      publicURL: string;
    },
    accentImage: {
      publicURL: string;
    }
  }
}

const Tagline = styled.p<any>`
  font-size: 20px;
  line-height: 24px;
  color: ${props => props.theme.secondaryColor};
  font-family: ${props => props.theme.contentFontFamily};
  font-weight: 400;
  padding: 80px 0 46px 0;
  margin: 0;
`;

const LandingFormWrapper = styled.div<any>`
  border: 1px solid ${props => props.theme.primaryColor};
  background: #fff;
  position: relative;
  max-width: 469px;
  min-height: 620px;
  margin: 0 auto;

  &:before {
    background: none;
    border: 1px solid #1E3961;
    content: " ";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    pointer-events: none;
    transition: border-color .2s ease-in-out;
  }

  @media (min-width: ${props => props.theme.lg}px) {
    margin: 0 0 40px auto;
  }
`;

const LandingForm = styled.div<any>`
  padding: 57px 27px 37px;
  margin: 0;

  h2 {
    font-size: 19px;
    line-height: 24px;
    font-weight: normal;
    text-align: right;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin: 20px auto;
  }

  iframe {
    height: 100%;
  }
`;

const FormInput = styled.input<any>`
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 0;
  display: block;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
`;

const LandingPageTopWrapper = styled.div<any>`
  background-color: ${props => props.theme.backgroundColor};
  background: url(${props => props.image}) no-repeat bottom center;
  background-size: cover;

  @media (min-width: ${props => props.theme.lg}px) {
    background-size: 3500px 1449px;
    margin: 0 auto 100px auto;
  }
`;

const LandingPageContent = styled.div<any>`
  h1,
  h2,
  h3 {
    font-weight: normal;
  }

  h1 {
    font-size: 46px;
    line-height: 49px;
    margin-top: 0;
  }

  h2 {
    font-size: 36px;
    margin-top: 50px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-family: ${props => props.theme.contentFontFamily};
    font-size: 25px;
    line-height: 30px;
  }

  ul {
    list-style-type: circle;
    padding-left: 40px;
  }

  li {
    font-family: ${prosp => prosp.theme.contentFontFamily};
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }

  @media (min-width: ${props => props.theme.lg}px) {
    float: left;
    max-width: 50%;
  }
`;

const PageContentWhite = styled(LandingPageContent)<any>`
  float: none;
  padding: 50px 0 70px;
  max-width: 100%;
  background: #fff;

  p {
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: ${props => props.theme.lg}px) {
    float: none;
    max-width: 100%;
  }
`;

const HeaderImage = styled.img<any>`
  width: 500px;
  margin: 50px auto 50px;
  display: block;
  
  @media (min-width: ${props => props.theme.lg}px) {
    width: auto;
    margin: 50px -60px 0 -60px;
    max-width: 650px;
  }
`;

const Row = styled.div<any>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
  }
`;

export default function QatchCaseStudy(props: ILandingPageProps): JSX.Element {

  const FooterCTA = () => {
    return (
      <FooterCta
        title="At Strategica Partners, we help forward-thinking companies launch world-changing products."
        callout="Are you Ready?"
      />
    )
  }

  const onFormSubmitted = () => {
    console.log("submitted");
  }

  const {
    topImage,
    midImage,
    accentImage,
  } = props.data;

  return (
    <Layout
      title="Landing Page 1"
      hideContactFooterBanner
      FooterCTA={FooterCTA}
      landingPage
    >
      <LandingPageTopWrapper image={accentImage.publicURL}>
        <Container>
          <Tagline>Learn how Quatch maximized growth and increased their conversion rate by 20%.</Tagline>
          <LandingPageContent>
            <h1>Identify key marketing opportunities and make better-informed decisions to ensure sustainable growth.</h1>
            <p>Sign-up and download our case study to learn how Strategica Partners helped Qatch to hone its unique value proposition to increase brand awareness and highlight its competitive advantages.</p>
            <HeaderImage src={topImage.publicURL} alt="Qatch-Case-Study catalog booklet"/>
          </LandingPageContent>
          <LandingFormWrapper>
            <LandingForm>
              <h2>Fill out the form below and learn how to maximize growth and increase customer retention.</h2>
              <Separator margin="20px 0 40px auto" />
              <HubspotForm formId="61309f81-b7db-4360-bb9d-f657e336973c" onFormSubmitted={onFormSubmitted} />
            </LandingForm>
          </LandingFormWrapper>
        </Container>
      </LandingPageTopWrapper>
      <PageContentWhite>
        <Container>
          <h2>Ready to gain a clearer perspective on your Audience?</h2>
          <p>Growing a startup with little to no marketing or data experience can be challenging. Identifying key performance indicators to help measure your success can decrease the risk of overlooking missed opportunities and can help you to deliver a consistent, branded experience across all marketing channels.</p>
          <p><strong>Download our case study</strong> to learn how Qatch leveraged the power of quizzes to understand their users’ interests and what they loved about the brand. You’ll learn how Qatch leveraged A/B testing to maximize marketing resource efficiency and set up test email campaigns for different goals and results.</p>
          <h2>Topics include:</h2>
          <Row>
            <ul>
              <li>Personalized marketing insights</li>
              <li>Leveraging Google Analytics</li>
              <li>Website optimization</li>
              <li>A/B testing</li>
              <li>Brand optimization</li>
            </ul>
            <img src={midImage.publicURL} alt="Qatch-Case-Study ebook preview" />
          </Row>
          <p>Are you ready to learn how Qatch maximized growth and increased their conversion rate by 20%? Download our case study and learn how a Strategic Advisory Retainer can help your business make better-informed marketing decisions.</p>
        </Container>
      </PageContentWhite>
    </Layout>
  )
}

export const query = graphql`
{
  topImage: file(relativePath: {eq: "qatch_brochures_closed_2x.png"}) {
    publicURL
  }
  midImage: file(relativePath: {eq: "qatch_brochures_open.png"}) {
    publicURL
  }
  accentImage: file(relativePath: {eq: "landing-accent-bg.jpg"}) {
    publicURL
  }
}
`;